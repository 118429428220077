import { useReducer } from 'react';
import update from 'immutability-helper';
import { navigate } from 'gatsby';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Link } from 'components';
import { SitePage } from 'app/layout';
import { useAppDispatch, createProfile, useAppSelector } from 'app/store';
import { QuestionStepper } from 'app/survey/questions';
import { getProfileQuestions } from 'app/profile/getProfileQuestions';
import create_profile from 'app/profile/create_profile.png';

import { ensureAuth } from 'app/helpers';
import { RctSurveyKeys, UserDetails } from '@vega/common';
import { CONFIGURATION } from 'config';

// We lie to the type system to make `QuestionStepper` happy. `QuestionStepper` expects
// a valid survey key (i.e. a key that exists in the `rctMachine`)
const SURVEY_KEY = 'user-profiling' as RctSurveyKeys;

type CreateProfileState = {
  step: number;
  user: Partial<UserDetails>;
};

type Action =
  | {
      type: 'set-step';
      payload: number;
    }
  | {
      type: 'update-user';
      payload: Partial<UserDetails>;
    };

const setStep = (step: number): Action => {
  return {
    type: 'set-step',
    payload: step,
  };
};

const updateUser = (data: Partial<UserDetails>): Action => {
  return {
    type: 'update-user',
    payload: data,
  };
};

const createProfileReducer = (state: CreateProfileState, action: Action) => {
  switch (action.type) {
    case 'set-step':
      return update(state, { step: { $set: action.payload } });

    case 'update-user':
      return update(state, { user: { $merge: action.payload } });

    default:
      return state;
  }
};

const questions = getProfileQuestions();

function CreateProfilePage({ onSubmit }: { onSubmit: (data: UserDetails) => void }) {
  const [state, dispatch] = useReducer(createProfileReducer, { step: -1, user: {} });
  const { step, user } = state;
  const { userInfo, loaded } = useAppSelector((state) => state.auth);

  const handleSetStep = async (step: number) => {
    dispatch(setStep(step));
    if (step === questions.length && loaded && userInfo) {
      onSubmit({
        ...state.user,
      } as UserDetails);
    }
  };

  return (
    <SitePage>
      <Container sx={{ pb: 8 }}>
        <Box pt={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography color="textPrimary">Create profile</Typography>
          </Breadcrumbs>

          <Grid
            container
            alignItems="center"
            sx={{
              bgcolor: blueGrey[50],
              mt: 2,
            }}
          >
            <Grid item sm={12} md={6}>
              <Box
                p={4}
                display="flex"
                justifyContent="center"
                sx={{
                  '&>img': {
                    width: '80%',
                    height: '80%',
                    maxWidth: '300px',
                    maxHeight: '300px',
                  },
                }}
              >
                <img src={create_profile} />
              </Box>
            </Grid>

            <Grid item sm={12} md={6}>
              <Box p={4}>
                <Typography variant="h4" gutterBottom>
                  Welcome
                </Typography>

                <Typography variant="body1" color="textSecondary" paragraph>
                  Welcome and congratulations on taking this step to support your child’s mental wellbeing. Before
                  you complete the baseline survey and get started with your program, please take a few moments to
                  complete your profile. The questions asked here are needed to personalise your{' '}
                  {CONFIGURATION.PROJECT_NAME} program.
                </Typography>

                <Box mb={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={step >= 0}
                    onClick={() => dispatch(setStep(0))}
                    sx={{
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    Start
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <QuestionStepper
                survey={{ sections: questions, key: SURVEY_KEY, category: 'rct' }}
                setStep={handleSetStep}
                allUsersSurveysProgress={{ 'user-profiling': { ...user, _step: step, _completed: 0, _total: 0 } }}
                onChange={async (data) => dispatch(updateUser(data))}
                finishLabel="Submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SitePage>
  );
}

export function CreateProfile() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profile.data);

  if (profileData !== null) {
    return (
      <Dialog open={true}>
        <DialogTitle>Thank you</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            You have now completed your profile. Check out your personal dashboard for the next steps of your program.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate('/dashboard', { replace: true })}>Go</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return <CreateProfilePage onSubmit={(data) => dispatch(createProfile(data))} />;
}

export default ensureAuth(CreateProfile, false);
