import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuestionSection } from '@vega/common';

const Article = styled('article')(({ theme }) => ({
  '& li': {
    ...theme.typography.body1,
  },
}));

/**
 * These questions are used to populate the user's profile. The questions here must match the `UserDetails` type.
 * This survey is mostly redundant, as the questions are now manually duplicated in the admin dashboard component
 * ParticipantAccessManagement.tsx.
 */
export const getProfileQuestions = (): QuestionSection[] => [
  {
    key: 'aboutUser',
    title: 'About you',
    questions: [
      {
        key: 'userFirstname',
        exportId: 'ippname',
        type: 'text',
        controlled: true,
        label: 'First name',
        required: true,
        size: 'medium',
      },
    ],
  },
  {
    key: 'aboutChild',
    title: 'About your child',
    questions: [
      {
        key: 'intro',
        type: 'paragraph',
        content: (
          <Article>
            <Typography paragraph color="textSecondary">
              If you have multiple children, please complete the information below in reference to the
              child who is enrolled in this research study.
            </Typography>
          </Article>
        ),
      },
      {
        key: 'childName',
        exportId: 'ipcname',
        type: 'text',
        controlled: true,
        label: 'First name',
        size: 'medium',
        required: true,
      },
      {
        key: 'childDateOfBirth',
        exportId: 'ipcdob',
        type: 'date',
        title: '',
        label: "What is your child's date of birth?",
        size: 'medium',
        required: true,
      },
    ],
  },
];
